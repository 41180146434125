import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import { PageLayout } from "../../layouts";
import { Block, GetStartedBlock, PersonalityBlock, TrackedLink } from '../../components';

// Background assets.
import Phone from "../../images/invite-page/phone-in-hand.svg";
import WhatIsPartieBg from "../../images/invite-page/what-is-partie-bg.svg";
import DownloadBg from "../../images/invite-page/download-partie.svg";
import PlayerGrid from "../../images/invite-page/player-grid.svg";
import Coins from "../../images/invite-page/coins.svg";

import "../../global/styles/page-styles/invite-page.scss";

const PartnerInvite = ({ data }) => {
  const [partner] = data?.allMysqlPartnerinvite?.nodes;

  /**
   * Add the FPO image to hero until live data is present.
   * Will be a string stored on the 'metadata' field.
   *
   * ie:
   * const parsedData = JSON.parse(partner.metadata);
   * const {img} = parsedData;
   *
   */
  const partnerData = {
    ...partner,
    imageSrc: "https://cdn.partie.com/downloads/assets/images/invite_graphic_22000.svg",
  };

  return (
    <>
      <Helmet>
        <title>{`Partie | ${partner.name}`}</title>
        <html lang="en" />
        <meta name="description" content={`${partner.name} invites you to join Partie!`} />
      </Helmet>
      <PageLayout className="invite-page">
        {/*
          Hero Block
        */}
        <PersonalityBlock data={partnerData} />

        {/*
          What is Partie Block
        */}
        <Block centered className="what-is-partie" lazy>
          <img
            className="what-is-partie__background"
            src={WhatIsPartieBg}
            alt="video games"
            loading="lazy"
            aria-hidden
          />
          <div className="partie-block__column">
            <h3 className="partie-block__title">What Is Partie?</h3>
            <p className="partie-block__description">
              Partie is an all new matchmaking and communications platform for
              the gaming community.
            </p>
          </div>
          <div className="partie-block__column" aria-hidden>
            <img
              src={Phone}
              alt=""
              aria-hidden
              loading="lazy"
              className="what-is-partie__primary-img"
            />
          </div>
        </Block>

        {/* 
          Download Partie Block
        */}
        <Block className="download-partie-cta-block" centered dark lazy>
          <div className="partie-block__column">
            <h3 className="partie-block__title">Download Partie</h3>
            <p className="partie-block__description">
              Available now for MacOS, Windows and Web.
            </p>
            <div className="partie-block__cta">
              <TrackedLink type="btn btn--accent" to="/download" children="Download" />
            </div>
          </div>
          <div className="partie-block__column" aria-hidden>
            <img src={DownloadBg} alt="" aria-hidden loading="lazy" />
          </div>
        </Block>

        {/* 
          Features Blocks
        */}
        <Block stacked lazy>
          <div className="partie-block__column" aria-hidden>
            <img
              className="linvite-page__img"
              src={PlayerGrid}
              alt=""
              loading="lazy"
              aria-hidden
            />
          </div>
          <div className="partie-block__column">
            <h3 className="partie-block__title">Create and Join Parties</h3>
            <p className="partie-block__description">
              Play your favorite games and challenges with the perfect squad.
            </p>
            <div className="partie-block__cta">
              <TrackedLink type="btn btn--accent" to="/features" children="View all features" />
            </div>
          </div>
        </Block>
        <Block stacked lazy>
          <div className="partie-block__column">
            <h3 className="partie-block__title">Earn Revenue</h3>
            <p className="partie-block__description">
              Get paid to play as a verified Partie Leader.
            </p>
            <div className="partie-block__cta">
              <TrackedLink type="btn btn--accent" to="/features" children="View all features" />
            </div>
          </div>
          <div className="partie-block__column">
            <img
              className="invite-page__img invite-page__img--coins"
              src={Coins}
              loading="lazy"
              alt="Fantasy Hero"
            />
          </div>
        </Block>

        {/* 
          Get Started Block
        */}
        <GetStartedBlock />
      </PageLayout>
    </>
  );
};

export const query = graphql`
  query PartnerInvite($code: String!) {
    allMysqlPartnerinvite(filter: { code: { eq: $code } }) {
      nodes {
        id
        name
        code
        metadata
      }
    }
  }
`;

export default PartnerInvite;